import { useContext, useEffect, useState } from "react";
import { useGetEditCategoryApi } from "../../../api/chat";
import "./suggestionDropdown.css";
import { MyContext } from "../../../context/Maincontext";
import { useLocation } from "react-router-dom";

const SuggestionDropdown = ({
  customstyle,
  onChange,
  isDisable,
  id,
  isEdit,
  finaldata,
  value, // Accept the value prop for controlled input
}) => {
  const { data } = useGetEditCategoryApi();
  const [optionApiValue, setOptionValue] = useState([]);
  const contextValue = useContext(MyContext);
  const [optionLabel, setOptionLabel] = useState([]);
  const [fetched, setFetched] = useState(true);

  useEffect(() => {
    if (data) {
      setOptionValue(data.data);
      contextValue?.handleSetStateOptions(data.data);
      setFetched(false);
    }
  }, [data]);

  const findoptionLabel = () => {
    return contextValue.state.options?.reduce((acc, item) => {
      if (item?.choice_type.toLowerCase() === id?.toLowerCase()) {
        return [...acc, { label: item.value, value: item.value, id: item.id }];
      }
      return acc;
    }, []);
  };
  return (
    <select
      className="suggestionselectcontainer"
      name={id}
      style={customstyle}
      onChange={onChange}
      disabled={isDisable}
    >
      <option value={""}>Choose</option>
      {(isEdit ? finaldata || [] : findoptionLabel())?.map((option) =>
        option?.value === "fill" ? (
          <option
            key={`${option?.value}-${id}`}
            id={option.id}
            value={`${option.value}-${id}`}
          >
            {option.label}
          </option>
        ) : (
          <option
            key={option?.value}
            id={id}
            value={option?.value}
            data-id={option?.id}
          >
            {option?.label}
          </option>
        )
      )}
    </select>
  );
};

export default SuggestionDropdown;
