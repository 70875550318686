import { useContext } from "react";
import {
  fetchAllConversations,
  fetchGetResponseQuery,
  useGetResponseQuery,
} from "./query";
import { BASE_URL } from "../constants";
import { api } from "./api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const conversationApi = async (setstate) => {
  const fetchconversation = await fetchAllConversations();

  setstate(fetchconversation.data);
};

export const GetGeneratedResponseApi = async (id, isHistory) => {
  try {
    const url = isHistory
      ? `${BASE_URL}/conversations/${id}/`
      : `${BASE_URL}/submit_query/${id}/`;
    const response = await fetchGetResponseQuery(url);
    return response;
  } catch (err) {
    return console.log(err);
  }
};

export const DeleteConversation = async (id) => {
  try {
    const response = await api.delete(`${BASE_URL}/conversations/${id}/`);
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const SuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  });
};

export const FailureToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  });
};
