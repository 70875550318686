import "./responseui.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SuggestionContent from "../../../components/suggestion";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getClassdata,
  useGetResponseApi,
  useModifyQueryApi,
} from "../../../api/chat";
import { Box, CircularProgress } from "@mui/material";
import { GetGeneratedResponseApi } from "../../../utils/commonunctions";
const ResponseUi = () => {
  // const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  let isHistory = location?.state?.isHistory ?? false;
  let id = location?.state?.id;
  let showres = location?.state?.showres;

  const [ismodify, setIsModify] = useState(false);
  const [response, setResponse] = useState("");
  const [query, setQuery] = useState();
  const [convoId, setConvoId] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const data = await GetGeneratedResponseApi(id, isHistory);
      setData(data);
      setIsLoading(false);
    };
    fetchData();
  }, [id, isHistory]);

  // const memoizedId = useMemo(() => id, [id]);
  const generateModifyResponse = useModifyQueryApi(convoId ?? id);
  const [initialValues, setInitialValues] = useState({});
  const [isFill, setIsFill] = useState({
    Output: false,
    Language: false,
    Length: false,
    subject: false,
    audience: false,
  });
  const [suggestionValue, setSuggestionValue] = useState({});
  const [modifyLoading, setModifyLoading] = useState(false);
  const [displayedText, setDisplayedText] = useState("");
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [showResponse, setShowResponse] = useState(showres);

  useEffect(() => {
    const lines = response?.split("\n"); // Split the response into lines
    if (currentLineIndex < lines?.length) {
      const words = lines[currentLineIndex].split(" "); // Split current line into words

      if (currentWordIndex < words.length) {
        const timeout = setTimeout(() => {
          setDisplayedText(
            (prevText) => prevText + words[currentWordIndex] + " "
          );
          setCurrentWordIndex(currentWordIndex + 1); // Move to the next word
        }, 100); // Delay for each word (300ms)

        return () => clearTimeout(timeout); // Clean up timeout
      } else {
        // Move to the next line when the current line is done
        const lineTimeout = setTimeout(() => {
          setDisplayedText((prevText) => prevText + "\n"); // Add a line break
          setCurrentWordIndex(0); // Reset word index for the new line
          setCurrentLineIndex(currentLineIndex + 1); // Move to the next line
        }, 100); // Delay after completing a line (1 second)

        return () => clearTimeout(lineTimeout);
      }
    }
  }, [currentWordIndex, currentLineIndex, response]);
  useEffect(() => {
    getClassdata();
  }, []);

  useEffect(() => {
    setQueries();
  }, [id, isHistory, data]);

  const setQueries = () => {
    setConvoId(data?.data?.queries?.map((e) => e.id)?.toString());
    if (data?.data?.queries?.length > 0) {
      if (
        data?.data?.queries?.length > 0 &&
        data?.data?.queries[0]?.modified_response
      ) {
        setResponse(data?.data?.queries[0]?.modified_response);
        setSuggestionValue(data?.data?.queries[0]?.options);
        setQuery(data?.data?.queries[0]?.query);
      } else if (
        data?.data?.queries?.length > 0 &&
        data?.data?.queries[0]?.response
      ) {
        setResponse(data?.data?.queries[0]?.response);
        setSuggestionValue(data?.data?.queries[0]?.options);
        setQuery(data?.data?.queries[0]?.query);
      }
    } else {
      setResponse(data?.data?.response);
      setSuggestionValue(data?.data?.options);
      setQuery(data?.data?.query);
    }
  };

  const modifyResponse = async () => {
    setIsModify(true);
    let modifyValue = {
      generated_prompt: query,
      input_for_modification: suggestionValue,
      response_from_gpt: response ? response : data?.data?.response,
    };
    setModifyLoading(true);
    let modifiedData = await generateModifyResponse.mutateAsync(modifyValue);
    setModifyLoading(false);
    setResponse(modifiedData?.data?.modified_response);
  };

  const loader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* <CircularProgress size={100} /> */}
        <span>Generating Query </span>
        <span class="dot-one"> .</span>
        <span class="dot-two"> .</span>
        <span class="dot-three"> .</span>
      </Box>
    );
  };

  return (
    <>
      <div className="responseuiinputcontainer">
        {query ? (
          <AccountCircleIcon
            style={{
              background: "intial",
              display: query ? `` : `none`,
            }}
          />
        ) : null}

        {query ? (
          <p
            className="responseuiinput"
            style={{ display: query ? `` : `none` }}
          >
            {query ?? ""}
          </p>
        ) : null}
      </div>
      {showResponse ? (
        <>
          <p style={{ fontSize: "22px" }}>
            {ismodify ? "Modified Response" : "Response"}
          </p>
          <div className="responsetextdiv">
            {isLoading || modifyLoading ? (
              loader()
            ) : (
              <p className="responestyle" style={{ whiteSpace: "pre-line" }}>
                {response}
              </p>
            )}
          </div>
        </>
      ) : (
        ""
      )}

      <div style={{ marginTop: "4%" }}>
        <SuggestionContent
          isClass={false}
          onModifyClick={modifyResponse}
          isFill={isFill}
          setIsFill={setIsFill}
          // suggestionValue={suggestionValue}
          setSuggestionValue={setSuggestionValue}
          setShowResponse={setShowResponse}
          showResponse={showResponse}
          resdata={data}
        />
      </div>
    </>
  );
};

export default ResponseUi;
