import { SuggestionBtnData } from "../../utils/helperdata";
import SuggestionButton from "../common/suggestionButton/suggestionButton";
import Grid from "@mui/material/Grid2";
import SuggestionDropdown from "../common/suggestionDropdown";
import SuggestionFill from "../common/suggestionFill";
import QueryButton from "../common/querybutton";
import "./suggestion.css";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { FailureToast, SuccessToast } from "../../utils/commonunctions";
import { ToastContainer } from "react-toastify";
import { MyContext } from "../../context/Maincontext";
import { Box, Button, Modal } from "@mui/material";
import AddSuggestionModal from "./addSuggestionModal";
import { getClassdata } from "../../api/chat";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  createClassesApi,
  deleteClassesApi,
  editClassesApi,
} from "../../utils/query";
import { BASE_URL } from "../../constants";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import axios from "axios";

const SuggestionContent = ({
  isClass = true,
  onModifyClick,
  onGenerateQuery,
  suggestionValue,
  setSuggestionValue,
  onSaveQuery,
  isFill,
  setIsFill,
  setShowResponse,
  showResponse,
  resdata,
}) => {
  const navigate = useNavigate();
  const contextValue = useContext(MyContext);
  const [ddata, setData] = useState([]); // Mutable copy of the array
  const [shouldFetch, setShouldFetch] = useState(true); // Flag to avoid repeated calls
  const [ddataLength, setDdataLength] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModel] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };
  const handleClose = () => setOpen(false);
  const initialForm = {
    name: "",
    icon: "",
    is_deleted: true,
    is_option: false,
  };
  const [formData, setFormData] = useState(initialForm);
  // Open the modal
  const handleAddClick = (action, formData) => {
    setIsModalOpen(true);
    setIsEditModel(false);
    if (action == "edit") {
      setIsEditModel(true);
      setFormData(formData);
    }
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialForm);
  };

  // Save new object into the array
  const handleSaveData = async (newData) => {
    const createClasses = await createClassesApi(
      `${BASE_URL}/categories/`,
      newData
    );

    if (createClasses.status == 200) {
      FailureToast(createClasses?.data?.message);
    }
    if (createClasses.status == 201) {
      setShouldFetch(true);
      setIsModalOpen(false); // Close modal
      setFormData(initialForm);
      SuccessToast("Added Sucessfully");
    }
  };

  const handleEditClass = async (newData) => {
    const body = {
      is_option: newData.is_option,
      name: newData.name,
    };

    const createClasses = await editClassesApi(
      `${BASE_URL}/categories/${newData.id}/`,
      body
    );
    if (createClasses.status == 200) {
      setShouldFetch(true);
      setIsModalOpen(false); // Close modal
      setFormData(initialForm);
      SuccessToast("Successfully Edited");
    }
  };

  const handleEdit = (options, id) => {
    const findoptionLabel = () => {
      return options?.reduce((acc, item) => {
        if (item?.choice_type.toLowerCase() === id?.toLowerCase()) {
          return [
            ...acc,
            { label: item.value, value: item.value, id: item.id },
          ];
        }
        return acc;
      }, []); // Initial value of acc as an empty array
    };

    const finaldata = findoptionLabel(); // Call findoptionLabel and store the result

    navigate("/edit", {
      state: { finaldata, id, isEdit: true },
    });
  };

  const handleDropdownnew = (e) => {
    const splittedValue = e?.target?.value?.split("-")[0];
    let selectedId =
      e.target.options[e.target.selectedIndex].getAttribute("data-id");

    if (splittedValue === "fill") {
      formik.setFieldValue(e.target.name, "");
      setIsFill({ ...isFill, [e.target.name]: false });
    } else {
      if (splittedValue === "") {
        formik.setFieldValue(e.target.name, "");
        setIsFill({ ...isFill, [e.target.name]: false });
      } else {
        formik.handleChange(e);
        setIsFill({ ...isFill, [e.target.name]: true });
      }
    }
  };

  const optionData = resdata?.data?.queries?.[0]?.options;

  const formik = useFormik({
    initialValues: {},
    validateOnChange: true,
    // validationSchema: Yup.object({
    //   subject: Yup.string().required("Subject is required"),
    // }),

    onSubmit: (values) => {
      console.log(values, "hi");
      // Filter out fields with empty or null values
      const filteredData = Object.keys(values).reduce((acc, key) => {
        if (values[key]) {
          // Check for non-empty strings
          acc[key] = values[key];
        }
        return acc;
      }, {});

      onGenerateQuery(filteredData);
    },
  });

  const handleModify = () => {
    setShowResponse(true);
    onModifyClick();
  };

  useEffect(() => {
    if (optionData && typeof optionData === "object") {
      Object.keys(optionData).forEach((key) => {
        let updatedKey = key;

        // Check if the key is "Output" and transform it to lowercase
        if (key === "Output") {
          updatedKey = "output";
        }
        formik.setFieldValue(updatedKey, optionData[key]); // Set the field value in Formik
      });
    }
  }, [resdata, suggestionValue]);

  useEffect(() => {
    const suggestionvalue = Object.keys(formik.values).reduce((acc, key) => {
      if (formik.values[key]) {
        // Check if the key is 'output' and convert it to 'Output'
        const modifiedKey = key === "output" ? "Output" : key;
        acc[modifiedKey] = formik.values[key];
      }
      return acc;
    }, {});

    setSuggestionValue(suggestionvalue);
  }, [formik?.isSubmitting, isFill, formik.isValidating]);

  useEffect(() => {
    if (!shouldFetch) return; // Prevent repeated API calls

    const fetchData = async () => {
      try {
        getClassdata(setData);
        setShouldFetch(false); // Set flag to false to prevent re-fetching
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [shouldFetch]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  const deleteOnsubmit = async () => {
    const deleteStatus = await deleteClassesApi(
      `${BASE_URL}/categories/${deleteId}/`
    );
    if (deleteStatus.status == 200) {
      setOpen(false);
      setShouldFetch(true);
      SuccessToast("successfully deleted");
    }
  };

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-name"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h3>Are you sure,you want to delete</h3>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                sx={{ backgroundColor: "#000", color: "#fff" }}
                onClick={deleteOnsubmit}
              >
                Yes
              </Button>
              <Button sx={{ border: "1px solid #000" }} onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      <ToastContainer />

      <form onSubmit={formik.handleSubmit}>
        <Button
          variant="contained"
          onClick={() => handleAddClick("create", null)}
          sx={{
            backgroundColor: "black",
            marginBottom: "20px",
            padding: "5px 20px",
            marginLeft: "5px",
          }}
        >
          + Add Class
        </Button>
        <Grid
          container
          style={{ width: "100%" }}
          alignItems="stretch"
          spacing={2}
        >
          {ddata?.map((data) => {
            return (
              <>
                <Grid
                  size={{ lg: 2, md: 4, xs: 4 }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "80%" }}>
                    <SuggestionButton data={data} />
                  </div>
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      gap: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    <div>
                      {data?.is_deleted === true ? (
                        <ModeEditOutlineIcon
                          sx={{
                            fontSize: "18px",
                            color: "#4b5669",
                            cursor: "pointer",
                          }}
                          onClick={() => handleAddClick("edit", data)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {data?.is_deleted === true ? (
                        <DeleteOutlineIcon
                          sx={{
                            fontSize: "18px",
                            color: "#4b5669",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpen(data?.id)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Grid>

                {/* {isClass && ( */}
                <Grid size={{ lg: 2, md: 3, xs: 4 }}>
                  {data?.is_option && (
                    <div style={{ display: "flex" }}>
                      <SuggestionDropdown
                        id={data?.name}
                        options={contextValue?.state?.options}
                        onChange={(e) => handleDropdownnew(e)}
                      />

                      <EditNoteIcon
                        onClick={() =>
                          handleEdit(contextValue?.state?.options, data?.name)
                        }
                        style={{ color: "#F29D38", cursor: "pointer" }}
                      />
                    </div>
                  )}
                </Grid>
                {/* )} */}
                <Grid size={{ lg: 8, md: 3, xs: 4 }}>
                  <SuggestionFill
                    type={"text"}
                    name={data?.name}
                    isDisable={isFill[data.name]}
                    id={data?.id}
                    handleChange={formik.handleChange}
                    value={formik.values[data?.name]}
                  />
                </Grid>
              </>
            );
          })}
          <div className="suggestioncontentbtn">
            {isClass ? (
              <>
                <QueryButton type={"submit"} btntext={"Generate Query"} />
                <QueryButton
                  type={"button"}
                  btntext={"Save Query"}
                  btnclick={onSaveQuery}
                />
              </>
            ) : (
              <QueryButton
                type={"button"}
                btntext={"Modify Response"}
                btnclick={handleModify}
              />
            )}
            {window.location.pathname === "/response" ? (
              <QueryButton
                btnclick={() => {
                  setShowResponse(!showResponse);
                }}
                btntext={showResponse ? "Hide Response" : "Show Response"}
              />
            ) : (
              ""
            )}
          </div>
        </Grid>
      </form>
      <AddSuggestionModal
        formData={formData}
        setFormData={setFormData}
        open={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveData}
        onEdit={handleEditClass}
        isEditModel={isEditModal}
      />
    </>
  );
};

export default SuggestionContent;
