import "./suggestionButton.css";
import language from "../../../assest/image/Language.png";
import account from "../../../assest/image/account.png";
import Lists from "../../../assest/image/Lists.png";
import output from "../../../assest/image/output.png";
import subject from "../../../assest/image/subject.png";
import CommentIcon from "@mui/icons-material/Comment";

const SuggestionButton = ({ data, customstyle = {} }) => {
  // Function to map the icon name to the actual image
  const iconfun = (iconName) => {
    const iconMap = {
      language: language,
      account: account,
      Lists: Lists,
      output: output,
      subject: subject,
      default: <svg data-testid="DeleteIcon"></svg>,
    };

    return iconMap[iconName]; // Return the matching icon or null if not found
  };

  return (
    <div className="suggestionContainer" style={customstyle}>
      <p style={{ margin: 0, width: "50%" }}>{data?.name}</p>{" "}
      {data?.icon ? (
        <img
          src={iconfun(data?.icon)}
          style={{ width: "50px", height: "17px", objectFit: "contain" }}
          alt="img"
        />
      ) : (
        <CommentIcon sx={{ fontSize: "16px", width: "40%", color: "green" }} />
      )}
    </div>
  );
};

export default SuggestionButton;
