import "./sidebar.css";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { sidebarData, sidebarDatalast } from "../../utils/helperdata";
import { useLocation, useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useGetAllConverstionApi } from "../../api/chat";
import MessageIcon from "@mui/icons-material/Message";
import { useContext, useEffect, useState } from "react";
import {
  conversationApi,
  DeleteConversation,
  SuccessToast,
} from "../../utils/commonunctions";
import { MyContext } from "../../context/Maincontext";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../constants";
import { api } from "../../utils/api";
import AlertDialogSlide from "./deleteModal";

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activedata = location?.state?.id;
  // const { data, isLoading } = useGetAllConverstionApi();
  const [Uidata, setUIdatas] = useState([]);
  const [weekdata, setWeekdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setopen] = useState(false);
  const contextValue = useContext(MyContext);

  useEffect(() => {
    setIsLoading(true);
    fetchAllConversations();
    if (contextValue?.state?.conversationData.count <= 0) {
      setIsLoading(false);
    }
    conversationApi(contextValue?.handleSetState);
    if (contextValue?.state?.conversationData.count) {
      setUIdatas(contextValue?.state?.conversationData);
      setIsLoading(false);
    }
  }, [contextValue?.state?.conversationData.count]);

  const fetchAllConversations = async () => {
    const response = await api.get(`${BASE_URL}/conversations/recent/`);
    setWeekdata(response?.data?.results);

    return response;
  };

  const handleNewChat = () => {
    navigate("/");
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const handlepwdchange = () => {
    navigate("/changePassword");
  };

  const handleNavigation = (i) => {
    navigate(`/response`, {
      state: { isHistory: true, id: i },
    });
  };

  const handleDeleteConversation = async (id) => {
    const data = await DeleteConversation(id);

    if (data?.status === 200 || data?.status === 204) {
      console.log("deleted");
      SuccessToast("Deleted successfully");
      navigate("/");
      conversationApi(contextValue?.handleSetState);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <div className="mainlogo">STRAND</div>
          <div className="hr"></div>
          <div className="maintitle">
            <AutoAwesomeIcon style={{ color: "#718096" }} />
            <p>Chat UI</p>
          </div>
          <button className="chatbtn" onClick={handleNewChat}>
            + New Chat
          </button>
          <div className="hr"></div>
          <div className="conversationarea">
            <p>Conversation</p>
            <button onClick={() => setopen(true)}>Clear all</button>
          </div>
          <div className="hr"></div>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                minHeight: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="black" />
            </Box>
          ) : (
            Uidata?.results?.map((data, index) => {
              return (
                <div
                  className={
                    activedata == data?.conversation_id
                      ? `chatflex-selected`
                      : `chatflex`
                  }
                  onClick={() => handleNavigation(data?.conversation_id)}
                >
                  <div
                    style={{
                      height: "22px",
                      color: "black",
                    }}
                  >
                    <MessageIcon />
                  </div>
                  <div
                    className="historytitlesection"
                    style={{
                      // color:
                      //   activedata == data?.conversation_id
                      //     ? "black"
                      //     : "rgb(113 128 150 / 28%)",
                      color: "black",
                      fontWeight:
                        activedata == data?.conversation_id ? "bolder" : "",
                    }}
                  >
                    <span
                      className="sidebar-title"
                      style={{
                        fontSize: "13px",
                        height: "3em",
                        lineHeight: "1.5em",
                        overflow: "hidden",
                      }}
                    >
                      {data.title}{" "}
                    </span>

                    {activedata == data?.conversation_id && (
                      <span
                        className="actionbtn"
                        onClick={() =>
                          handleDeleteConversation(data.conversation_id)
                        }
                      >
                        <DeleteForeverIcon fontSize="18px" />
                        {/* <ModeEditIcon fontSize="16px" /> */}
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          )}
          <div className="hr"></div>
          <p className="lastcontent">Last 7 Days</p>
          <div className="hr"></div>
          {weekdata?.map((data, index) => {
            return (
              <div
                className={
                  activedata == data?.conversation_id
                    ? `chatflex-selected`
                    : `chatflex`
                }
                onClick={() => handleNavigation(data?.conversation_id)}
              >
                <div
                  style={{
                    height: "22px",
                    color: "black",
                  }}
                >
                  <MessageIcon />
                </div>
                <div
                  className="historytitlesection"
                  // style={{
                  //   color:
                  //     activedata === data?.id
                  //       ? "black"
                  //       : "rgb(113 128 150 / 28%)",
                  // }}
                >
                  <span style={{ fontSize: "13px" }}>{data.title} </span>
                </div>
                {activedata == data?.conversation_id && (
                  <span
                    className="actionbtn"
                    onClick={() =>
                      handleDeleteConversation(data.conversation_id)
                    }
                  >
                    <DeleteForeverIcon fontSize="18px" />
                    {/* <ModeEditIcon fontSize="16px" /> */}
                  </span>
                )}
              </div>
            );
          })}
        </div>

        <div className="logout-wrapper">
          <button
            className="chatbtn"
            style={{ position: "sticky", bottom: 0 }}
            onClick={handlepwdchange}
          >
            Change Password
          </button>
          <button
            className="chatbtn"
            style={{ position: "sticky", bottom: 0 }}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <AlertDialogSlide
        open={open}
        setOpen={setopen}
        setUIdatas={setUIdatas}
        fetchapi={fetchAllConversations}
      />
    </>
  );
};
export default SideBar;
