import React, { useEffect, useState } from "react";
import CommonForm from "../../../components/common/LoginForm/CommonForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import EmailIcon from "@mui/icons-material/Email";
import { ForgotApi } from "../../../api/auth";
import loginimg from "../../../assest/image/loginbkg.png";
import { useNavigate } from "react-router-dom";
import { SuccessToast } from "../../../utils/commonunctions";
import { ToastContainer } from "react-toastify";

export default function ForgotPage() {
  const [disabelBtn, setDisablebtn] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const hanldeforgot = ForgotApi();
  const navigate = useNavigate();

  const formikreset = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("email is required"),
    }),

    onSubmit: (values) => {
      setIsLoading(true);
      hanldeforgot.mutate(values, setIsLoading);
    },
  });

  useEffect(() => {
    if (hanldeforgot.status === "pending") {
      setDisablebtn(true);
    }
    if (hanldeforgot?.isSuccess) {
      SuccessToast("Verification email sent successfully.");
      setDisablebtn(false);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [hanldeforgot?.isSuccess, hanldeforgot.status]);

  const Resetform = {
    title: "Verify mail",
    onsubmit: formikreset.handleSubmit,
    loader: isloading,
    btnText: "Verify",
    onClick: formikreset.handleSubmit,
    // disable: disabelBtn,
    backpath: "/",
    inputGroups: [
      {
        type: "email",
        placeholder: "Enter mail",
        name: "email",
        onChange: formikreset?.handleChange,
        icon: <EmailIcon />,
        value: formikreset.values.email,
        error: formikreset.errors.email,
        touched: formikreset.touched.email,
        fieldtype: true,
      },
    ],
  };
  return (
    // <div>
    //   asdasdasd
    //         {/* {CommonForm(Resetform)} */}
    //       </div>
    <div className="login-container">
      <ToastContainer style={{ width: "400px" }} />
      {/* Left Side - Login Form */}
      <div className="login-form-container">{CommonForm(Resetform)}</div>

      {/* Right Side - Robot Image */}
      <div className="login-image-container">
        {/* <div className="robot-image"></div> */}
        <img className="robot-image" src={loginimg} />
      </div>
    </div>
  );
}
