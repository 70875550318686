import axios from "axios";
import { BASE_URL } from "../constants";
import { FailureToast, SuccessToast } from "../utils/commonunctions";

import {
  useEditCategoryQuery,
  useGenerateQuery,
  useGenerateResponseQuery,
  useGetAllConversationQuery,
  useGetCategoryQuery,
  useGetResponseQuery,
  useModifyQuery,
  useSaveQuery,
} from "../utils/query";
const getToken = () => sessionStorage.getItem("token");

// export const useGenrateQueryApi = ()=> useGenerateQuery("https://fakestoreapi.com/products")
export const useGenrateQueryApi = () => useGenerateQuery(`${BASE_URL}/query/`);
export const useGenrateResponseApi = () =>
  useGenerateResponseQuery(`${BASE_URL}/submit_query/`);
export const useGetResponseApi = (id, isHistory) =>
  useGetResponseQuery(
    isHistory
      ? `${BASE_URL}/conversations/${id}/`
      : `${BASE_URL}/submit_query/${id}/`,
    id
  );
export const useGetAllConverstionApi = () =>
  useGetAllConversationQuery(`${BASE_URL}/conversations/`);
export const useSaveQueryApi = () => useSaveQuery(`${BASE_URL}/queries/`);
export const useModifyQueryApi = (id) =>
  useModifyQuery(`${BASE_URL}/modify_response/${id}/`);

export const useEditCategoryApi = () =>
  useEditCategoryQuery(`${BASE_URL}/choices/`);
export const useGetEditCategoryApi = () =>
  useGetCategoryQuery(`${BASE_URL}/choices/`);

export const getClassdata = async (setData) => {
  const token = getToken();
  try {
    const response = await axios
      .get("https://api.qtools.ai/categories/", {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      })
      .then((e) => {
        setData(e?.data);
      });
  } catch {
    console.log("catch response");
  }
};

export const deleteClassData = async () => {
  const token = getToken();
  try {
    const res = await axios
      .delete("http://api.qtools.ai/categories/1/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          getClassdata();
          SuccessToast("Deleted Successfully");
          console.log(res, "deleted");
        }
      })
      .catch((err) => {
        FailureToast("Deletion Failed");
      });
  } catch {}
};
