import { useMutation, useQuery } from "@tanstack/react-query";
import { api, authApi } from "./api";
import { BASE_URL } from "../constants";
import { FailureToast, SuccessToast } from "./commonunctions";

//Auth query

export const useLoginQuery = (url, data) => {
  return useMutation({
    mutationFn: (data) => authApi.post(url, data),
    onMutate: (data) => {
      return data;
    },
    onSuccess: (success) => {
      sessionStorage.setItem("token", success?.data?.access);
      return success;
    },
    onError: (err) => {
      return err;
    },
  });
};

export const ForgotPassQuery = (url, data) => {
  return useMutation({
    mutationFn: async (data) => {
      try {
        const response = await authApi.post(url, data);
        return response;
      } catch (error) {
        console.error("Error in mutationFn:", error); // Check if this logs the 404
        throw error; // Ensure error propagates to onError
      }
    },
    onMutate: (data) => {
      console.log("Mutation started with data:", data);
      return data;
    },
    onSuccess: (success) => {
      console.log("Success:", success);
      return success;
    },
    onError: (err) => {
      FailureToast(err?.response?.data?.error);
      return err;
    },
  });
};

export const useGenerateQuery = (url, data) => {
  return useMutation({
    mutationFn: (data) => api.post(url, data),
    onMutate: (data) => {
      console.log("dataaa", data);
      return data;
    },
    onSuccess: (success) => {
      console.log("sucecesss", success);
      return success;
    },
  });
};

export const useSaveQuery = (url, data) => {
  return useMutation({
    mutationFn: (data) => api.post(url, data),
    onMutate: (data) => {
      console.log("dataaa", data);
      return data;
    },
    onSuccess: (success) => {
      console.log("sucecesss", success);
      return success;
    },
  });
};
export const useModifyQuery = (url, data) => {
  return useMutation({
    mutationFn: (data) => api.post(url, data),
    onMutate: (data) => {
      console.log("dataaa", data);
      return data;
    },
    onSuccess: (success) => {
      console.log("sucecesss", success);
      return success;
    },
    onError: (err) => {
      console.log(err, "error");
      // return err
    },
  });
};
export const useEditCategoryQuery = (url, data) => {
  return useMutation({
    mutationFn: (data) => api.post(url, data),
    onMutate: (data) => {
      console.log("dataaa", data);
      return data;
    },
    onSuccess: (success) => {
      console.log("sucecesss", success);
      return success;
    },
  });
};

export const useGenerateResponseQuery = (url, data) => {
  return useMutation({
    mutationFn: (data) => api.post(url, data),
    onMutate: (data) => {
      console.log("dataaa", data);
      return data;
    },
    onSuccess: (success) => {
      console.log("sucecesss", success);
      return success;
    },
  });
};

export const useGetResponseQuery = (url, id) => {
  return useQuery({
    queryKey: [id],
    queryFn: () => api.get(url),
    // enabled: id
  });
};
export const useGetCategoryQuery = (url, id) => {
  return useQuery({
    queryKey: [id],
    queryFn: () => api.get(url),
    // enabled: id
  });
};

export const useGetAllConversationQuery = (url) => {
  return useQuery({
    queryFn: () => api.get(url),
  });
};

export const fetchAllConversations = async () => {
  const response = await api.get(`${BASE_URL}/conversations/`);

  return response;
};

export const fetchGetResponseQuery = async (url, id) => {
  try {
    const response = await api.get(url);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const ChangePasswordApi = async (data, navigate) => {
  try {
    const response = await api
      .post(`${BASE_URL}/change_password/`, data)
      .then((e) => {
        console.log(e, "cnhage");
        SuccessToast(e?.data?.message);
        navigate("/");
      })
      .catch((e) => {
        FailureToast(e?.response?.data?.error);
      });
    return response;
  } catch (err) {
    console.log(err);
  }
};

// export const createClassesApi = async (url, data) => {
//   try {
//     const response = await api
//       .post(url, data)
//       .then((e) => {
//         return response;
//         console.log(e, "ress");
//       })
//       .catch((e) => {
//         FailureToast(e?.response?.data?.message);
//         console.log(e, "errrr");
//       });
//   } catch (error) {
//     FailureToast(error);
//     console.error("Error fetching data:", error);
//     // throw error;
//   }
// };

export const createClassesApi = async (url, data) => {
  try {
    const response = await api.post(url, data);

    return response;
  } catch (error) {
    FailureToast(error?.response?.data?.message || "An error occurred");

    // throw error; // Re-throw the error if needed
  }
};

export const editClassesApi = async (url, data) => {
  console.log(data, "hhhhh");
  try {
    const response = await api.put(url, data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const deleteClassesApi = async (url) => {
  try {
    const response = await api.delete(url);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// export useGetGeneratedQuery =()
