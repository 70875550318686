import SuggestionButton from "../../../components/common/suggestionButton/suggestionButton";
import SuggestionDropdown from "../../../components/common/suggestionDropdown";
import subject from "../../../assest/image/subject.png";
import "./editsuggestion.css";
import QueryButton from "../../../components/common/querybutton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import { getFormattedDate } from "../../../utils/helperdata";
import { useLocation, useNavigate } from "react-router-dom";
import { useEditCategoryApi } from "../../../api/chat";
import { useContext, useState } from "react";
import { MyContext } from "../../../context/Maincontext";
import { SuccessToast } from "../../../utils/commonunctions";
import { api } from "../../../utils/api";

const EditSuggestion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editCategory = useEditCategoryApi();
  const [dataId, setDataId] = useState();
  const [categoryValue, setCategoryValue] = useState("");
  const contextValue = useContext(MyContext);
  const { id, isEdit, finaldata } = location?.state;

  const data = {
    title: id,
    icon: subject,
    isOption: false,
  };

  const handleAdd = async () => {
    const payload = {
      choice_type: id,
      value: categoryValue,
    };
    try {
      const response = await api
        .post("https://api.qtools.ai/choices/", payload)
        .then((e) => {
          if (e?.status === 201 || e?.status === 200) {
            SuccessToast("Choice Added Successfully");
            navigate(-1);
          }
        });

      // Handle successful response as needed
    } catch (error) {
      console.error("Error in POST request:", error);
      // Handle error as needed
    }
  };
  const handleEdit = async () => {
    const payload = {
      choice_type: id,
      value: categoryValue,
    };

    try {
      const response = await api
        .put(`https://api.qtools.ai/choices/${id}/${dataId}/`, payload)
        .then((e) => {
          console.log(e, "edited");
          if (e?.status === 200) {
            SuccessToast("Choice Edited Successfully");
            navigate(-1);
          }
        });
      console.log("Response data:", response.data);
      // Handle successful response as needed
    } catch (error) {
      console.error("Error in POST request:", error);
      // Handle error as needed
    }
  };
  const handleDelete = async () => {
    try {
      const response = await api
        .delete(`https://api.qtools.ai/choices/${id}/${dataId}/`)
        .then((e) => {
          if (e?.status === 200) {
            SuccessToast(e?.data?.message);
            navigate(-1);
          }
        });
    } catch (error) {
      console.error("Error in POST request:", error);
      // Handle error as needed
    }
  };

  const addCategory = async () => {
    navigate(-1);
  };

  const handleDropdownChange = (value) => {
    setDataId(
      value?.target?.options[value?.target?.selectedIndex].getAttribute(
        "data-id"
      )
    );

    // If "fill" is selected, clear the input field for manual input
    if (value?.target?.value.includes("fill")) {
      setCategoryValue("");
    } else {
      setCategoryValue(value?.target?.value); // Set value for other options
    }
  };

  return (
    <>
      <div className="maincontenthead">
        Chat UI <span className="maincontentdate">{getFormattedDate()}</span>
      </div>
      <div className="editsuggestioncontainer">
        <div className="editsuggestionoption">
          {/* <SuggestionButton data={data} customstyle={{ width: "30%" }} /> */}
          <SuggestionDropdown
            isEdit={isEdit}
            customstyle={{ width: "30%" }}
            finaldata={finaldata}
            // options={contextValue?.state?.options}
            value={categoryValue} // Pass value to dropdown
            onChange={handleDropdownChange} // Handle "fill" case
          />
        </div>
        <div className="editsuggestionfillsection">
          <input
            className="suggestioninput"
            placeholder="Edit Category"
            value={categoryValue}
            onChange={(e) => setCategoryValue(e?.target?.value)}
          />
          <div style={{ display: "flex", maxHeight: "27px" }}>
            <div onClick={() => handleAdd()}>
              <AddCircleOutlineIcon
                style={{ color: "#35A600", fontSize: "25px" }}
              />
            </div>
            <div onClick={handleEdit}>
              <EditNoteIcon style={{ color: "#F29D38", fontSize: "25px" }} />
            </div>
            <div onClick={handleDelete}>
              <DeleteIcon style={{ color: "#F70000", fontSize: "25px" }} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
      >
        <QueryButton btntext={"Cancel"} btnclick={addCategory} />
      </div>
    </>
  );
};

export default EditSuggestion;
