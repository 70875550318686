import { useEffect, useRef } from "react";
import "./suggestionfill.css";
const SuggestionFill = ({
  placeholder = "Fill in",
  isDisable = false,
  id,
  suggestionValue,
  setSuggestionValue,
    handleChange,
  name,
  value,
  type,
}) => {
  const inputRef = useRef();
//   const handleChange = (e) => {
//     const { value, name } = e?.target;
//     setSuggestionValue({ ...suggestionValue, [name]: value });
//   };
  return (
    <>
      <input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        name={name}
        disabled={isDisable}
        placeholder={placeholder}
        type={type}
        className="suggestioninput"
      />
    </>
  );
};

export default SuggestionFill;
