import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/sidebar";
import Maincontext from "../../context/Maincontext";
import { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";

const Layout = () => {
  const [state, setState] = useState({
    conversationData: [],
    options: [],
  });

  const handleSetState = (value) => {
    setState((prev) => ({
      ...prev,
      conversationData: value,
    }));
  };

  const handleSetStateOptions = (value) => {
    setState((prev) => ({
      ...prev,
      options: value,
    }));
  };

  return (
    <>
      <Maincontext value={{ state, handleSetState, handleSetStateOptions }}>
        <Grid container sx={{ minWidth: "80vw", overflowX: "auto" }}>
          <Grid size={{ xs: 2, md: 2, lg: 2 }} className="leftcontainer">
            <SideBar />
          </Grid>
          <Grid size={{ xs: 10, md: 10, lg: 10 }} className="rightcontainer">
            <Container>
              <Outlet />
            </Container>
          </Grid>
        </Grid>
      </Maincontext>
    </>
  );
};

export default Layout;
