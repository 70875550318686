import React, { useState } from "react";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const AddSuggestionModal = ({
  open,
  onClose,
  onSave,
  formData,
  setFormData,
  isEditModel,
  onEdit,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, is_option: e?.target?.checked });
  };

  const handleSave = () => {
    if (!formData.name) {
      alert("Please fill all fields!");
      return;
    }
    onSave(formData);
  };
  const handleEdit = () => {
    if (!formData.name) {
      alert("Please fill all fields!");
      return;
    }
    onEdit(formData);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-name"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          />
          <Typography id="modal-modal-name" variant="h6" component="h2">
            {!isEditModel ? "Add" : "Edit"} Class
          </Typography>
          <TextField
            name="name"
            label="Title"
            fullWidth
            margin="dense"
            value={formData.name}
            onChange={handleInputChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData?.is_option}
                onChange={handleCheckboxChange}
              />
            }
            label="Enable Categories"
          />
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              variant="contained"
              onClick={isEditModel ? handleEdit : handleSave}
              style={{ background: "black", width: "100px", top: "10px" }}
            >
              SAVE
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddSuggestionModal;
