import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import axios from "axios";
import { FailureToast, SuccessToast } from "../../utils/commonunctions";
import { fetchAllConversations } from "../../utils/query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  setOpen,
  fetchapi,
  setUIdatas,
}) {
  const handleDelete = async () => {
    const refreshToken = sessionStorage.getItem("token");
    try {
      const response = await axios
        .patch("https://api.qtools.ai/clear_conversation//", null, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        })
        .then((e) => {
          setOpen(false);
          if (e?.status === 201 || e?.status === 200) {
            fetchapi();
            setUIdatas([]);
            SuccessToast("Deleted Successfully");
          }
        })
        .catch((e) => {
          setOpen(false);
          FailureToast(e?.response?.data?.message);
        });
    } catch (error) {
      console.error("Error in POST request:", error);
      // Handle error as needed
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpen}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontFamily: "lufga" }}>
          {"Are you sure you want to delete the conversation ?"}
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              fontFamily: "lufga",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ fontFamily: "lufga", backgroundColor: "black" }}
            onClick={handleDelete}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
