import axios from 'axios';
import { useEffect } from 'react';


const getToken = () => sessionStorage.getItem('token');
// let token  = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI3ODYzNjEzLCJpYXQiOjE3Mjc3NzcyMTMsImp0aSI6Ijk0YzY0YWI4YjEwNDQ5ZjNiMzg3NTRhODc1NWM4YTkxIiwidXNlcl9pZCI6NH0.7lgMQxUeCXpAAY9RNxvmeqV7rvdmakdhth_1W3zTtwQ"
export const api = {
    post: (url, data) => {
        const token = getToken();
        return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
    },
    delete: (url, data) => {
        const token = getToken();
        return axios.delete(url, {
            headers: { Authorization: `Bearer ${token}` },
            data: data ? data : {}
        });
    },
    get: (url, params) => {
        const token = getToken();
        return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    },
    put: (url, data) => {
        console.log(data,"hhhhhhhhhh")
        const token = getToken();
        return axios.put(url, data, { headers: { Authorization: `Bearer ${token}` } });
    }
    
};

export const authApi = {
    post: (url, data) => axios.post(url, data),
};