import Grid from "@mui/material/Grid2";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGenrateQueryApi,
  useGenrateResponseApi,
  useGetAllConverstionApi,
  useSaveQueryApi,
} from "../../api/chat";
import { getCurrentDate, getFormattedDate } from "../../utils/helperdata";
import QueryButton from "../common/querybutton";
import SuggestionContent from "../suggestion";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./maincontent.css";
import { CleaningServices } from "@mui/icons-material";
import { conversationApi, FailureToast } from "../../utils/commonunctions";
import { MyContext } from "../../context/Maincontext";
const MainContent = () => {
  const navigate = useNavigate();
  const [querymessage, setQuerymessage] = useState("");

  const [isGenerating, setIsGenerating] = useState(false);
  const [isFill, setIsFill] = useState({
    output: false,
    language: false,
    length: false,
  });
  const [suggestionValue, setSuggestionValue] = useState({
    subject: "",
    Output: "",
    Language: "",
    Length: "",
    audience: "",
  });

  const generateMutateQuery = useGenrateQueryApi();
  const generateResponseQuery = useGenrateResponseApi();
  const saveGeneratedQuery = useSaveQueryApi();
  const contextValue = useContext(MyContext);

  const SubmitQuery = async () => {
    if (querymessage !== "") {
      setIsGenerating(true);
      const { data } = await generateResponseQuery.mutateAsync({
        query: querymessage,
        options: suggestionValue,
        new_conversation: true,
      });
      console.log(data, "datttaa");
      setIsGenerating(false);
      conversationApi(contextValue?.handleSetState);
      navigate(`/response `, { state: { id: data?.id, showres: true } });
    } else {
      FailureToast("Please generte a query");
    }
  };

  const saveQuery = async () => {
    if (querymessage !== "") {
      let apiresponse = await saveGeneratedQuery.mutateAsync({
        data: { ...suggestionValue, ["query"]: querymessage },
      });
    } else {
      FailureToast("Please generte a query");
    }
  };
  const generateQuery = async (incomingdata) => {
    if (incomingdata?.subject) {
      let data = await generateMutateQuery.mutateAsync(incomingdata);
      if (data) {
        let apigeneratedQuery = data?.data?.query;
        setQuerymessage(apigeneratedQuery);
      }
    } else {
      FailureToast("Please select a subject");
    }
  };

  function CircularIndeterminate() {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {/* Genreate Query <CircularProgress /> */}
        <span>Generating Query </span>
        <span class="dot-one"> .</span>
        <span class="dot-two"> .</span>
        <span class="dot-three"> .</span>
      </Box>
    );
  }

  return (
    <div className="maincontentcontainer">
      <div className="maincontenthead">
        Chat UI <span className="maincontentdate">{getFormattedDate()}</span>
      </div>

      <SuggestionContent
        isFill={isFill}
        setIsFill={setIsFill}
        onGenerateQuery={generateQuery}
        suggestionValue={suggestionValue}
        setSuggestionValue={setSuggestionValue}
        onSaveQuery={saveQuery}
      />

      <Grid container style={{ width: "100%" }} spacing={2}>
        <Grid size={{ xs: 2, md: 8, lg: 9 }}>
          <div
            className="maincontentinput"
            rows="4"
            cols="100"
            value={querymessage}
            readOnly
          >
            {!querymessage ? <p>Generated query</p> : querymessage}
          </div>
        </Grid>
        <Grid size={{ xs: 2, md: 8, lg: 3 }}>
          <QueryButton
            btntext={isGenerating ? CircularIndeterminate() : "Submit Query"}
            isDisabled={querymessage ? false : true}
            btnclick={() => SubmitQuery()}
            customstyle={{
              width: " 100%",
              height: "55px",
              fontSize: " 17px",
              borderRadius: "35px",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MainContent;
