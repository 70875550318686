import { useNavigate, useParams } from "react-router-dom";
import LoginPage from "../../pages/auth/login";
import ForgotPage from "../../pages/auth/login/forgotPage";
import Resetpage from "../../pages/auth/login/Resetpage";
import ChangePassword from "../../pages/auth/login/changePassword";

export const authRoutes = [
  {
    path: "/",
    // element:<button onClick={()=>sessionStorage.setItem('UR',1)}>Login</button>
    element: <LoginPage />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPage />,
  },
  {
    path: `/changePassword/:uidb64?/:token?`,
    element: <ChangePassword />,
  },
  {
    path: "/resetPassword",
    element: <Resetpage />,
  },
];
