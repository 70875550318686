// import React from "react";
// import { Container, TextField, Button, Typography, Box } from "@mui/material";
// import { AccountCircle, Lock } from "@mui/icons-material";
// import Grid from "@mui/material/Grid2";
// import PersonIcon from "@mui/icons-material/Person";
// import LockIcon from "@mui/icons-material/Lock";
// import "./login.css"
// const LoginPage = () => {
//   return (
//     <Container maxWidth="lg">
//       <Grid >
//         <Grid size={{ lg: 8, md: 3, xs: 12 }}
//   display="flex" justifyContent="center" alignItems="center" >
//           <div></div>
//           <div >
//             <div className="authinput">
//               <PersonIcon />
//               <input placeholder="Enter Email"/>
//             </div>
//             <div className="authinput">
//               <LockIcon />
//               <input  placeholder="Enter passsword"/>
//             </div>
//           </div>
//         </Grid>
//         <Grid size={{ lg: 4, md: 3, xs: 12 }}></Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default LoginPage;

import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import loginimg from "../../../assest/image/loginbkg.png";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useLoginApi } from "../../../api/auth";
import { CleaningServices } from "@mui/icons-material";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Loginbutton from "../../../components/common/loginButton/loginbutton";
import CommonForm from "../../../components/common/LoginForm/CommonForm";
import { FailureToast, SuccessToast } from "../../../utils/commonunctions";
import { ToastContainer } from "react-toastify";
const LoginPage = () => {
  const navigate = useNavigate();
  const hanldeLogin = useLoginApi();
  const [eyeDefault, setEyeDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [userDetails, setUserDetails] = useState({
  //   username: "", password: ""
  // })

  // const handleChange = (e) => {
  //   const { value, name } = e?.target;
  //   setUserDetails({ ...userDetails, [name]: value })
  // }

  const EnterFunc = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: (values) => {
      handleLogin();
    },
  });

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const data = await hanldeLogin.mutateAsync(formik.values);
      if (data.status === 200) {
        sessionStorage.setItem("UR", 1);
        SuccessToast("Logged in successfully");
        setTimeout(() => navigate("/"), 1500);
      } else {
        FailureToast("Unexpected response from server.");
      }
    } catch (error) {
      if (error.status === 401) {
        FailureToast(error.response?.data?.detail);
      } else {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unknown error occurred";
        FailureToast(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      {/* Left Side - Login Form */}
      <div className="login-form-container">
        <ToastContainer style={{ width: "400px" }} />

        <div className="login-box">
          <h1 className="login-title">Login</h1>
          <div className="underline"></div>

          <form onSubmit={formik.handleSubmit}>
            <div>
              {formik.errors.username && formik.touched.username ? (
                <p className="error-txt">Username is required</p>
              ) : (
                ""
              )}
              <div
                className={
                  formik.errors.username && formik.touched.username
                    ? "input-group-red"
                    : "input-group"
                }
              >
                <span className="icon">
                  <PersonIcon />
                </span>{" "}
                {/* Unicode for user icon */}
                <input
                  type="name"
                  placeholder="Enter username"
                  name="username"
                  id="username"
                  formik={formik}
                  onKeyDown={EnterFunc}
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  className="input-field"
                />
              </div>

              {/* Password Input */}
              {formik.errors.password && formik.touched.password ? (
                <p className="error-txt">Password is required</p>
              ) : (
                ""
              )}
              <div
                className={
                  formik.errors.password && formik.touched.password
                    ? "input-group-red"
                    : "input-group"
                }
              >
                <span className="icon">
                  <LockIcon />
                </span>{" "}
                {/* Unicode for lock icon */}
                <input
                  type={eyeDefault ? "text" : "password"}
                  placeholder="Enter password"
                  name="password"
                  onChange={formik.handleChange}
                  onKeyDown={EnterFunc}
                  value={formik.values.password}
                  className="input-field"
                />
                <span
                  className="icon-eye"
                  onClick={() => setEyeDefault(!eyeDefault)}
                >
                  {eyeDefault ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </span>{" "}
                {/* Unicode for eye icon */}
              </div>
            </div>

            <div className="forgot-password">
              <a
                href="#"
                className="fr-btn"
                onClick={() => navigate("/forgotPassword")}
              >
                Forgot Password?
              </a>
            </div>
            {Loginbutton({ text: "Login", type: "submit", loader: isLoading })}
          </form>
          {/* Email Input */}

          {/* Forgot Password */}

          {/* Login Button */}
        </div>
      </div>

      {/* Right Side - Robot Image */}
      <div className="login-image-container">
        {/* <div className="robot-image"></div> */}
        <img className="robot-image" src={loginimg} />
      </div>
    </div>
  );
};

export default LoginPage;
