import { CircularProgress } from "@mui/material";
import React from "react";

export default function Loginbutton({ text, type, onClick, loader }) {
  return (
    <button
      className="login-btn"
      type={type ?? "button"}
      onClick={onClick}
      disabled={loader}
    >
      {loader ? <CircularProgress size={30} color="inherit" /> : text}
    </button>
  );
}
