import React from 'react'
import { createContext } from 'react';
export const MyContext = createContext("");

export default function Maincontext({children,value}) {

  return (
    <MyContext.Provider value={value}>{children}</MyContext.Provider>
  )
}
